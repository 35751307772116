$(document).on("turbolinks:load", function() {
var selectizeCallback = null;

$(".tag-modal").on("hide.bs.modal", function(e) {
  if (selectizeCallback != null) {
    selectizeCallback();
    selecitzeCallback = null;
  }

  $("#new_tag").trigger("reset");
	var selectors = [$.rails.linkDisableSelector, $.rails.formEnableSelector].join(', ');
    $(selectors).each(function() {
      $.rails.enableElement(this);
    })
});

$("#new_tag").on("submit", function(e) {
  e.preventDefault();
  $.ajax({
    method: "POST",
    url: $(this).attr("action"),
    data: $(this).serialize(),
    success: function(response) {
      selectizeCallback({value: response.id, text: response.name});
      selectizeCallback = null;

      $(".tag-modal").modal('toggle');
    }
  });
});

$(".selectize").selectize({
  // maxItems: 5,
  create: function(input, callback) {
    selectizeCallback = callback;

    $(".tag-modal").modal();
    $("#tag_name").val(input);
  }
});

var tab = $('.faq-row-handle > a'),
		tabContent = $('.faq-row-content'),
		tabParent = $('.faq-row');

		if(tab.length){

			tab.off('click').on('click', function(){

				var ele = $(this),
					currContent = $('#'+ele.attr('rel'));

					if(!ele.hasClass('open')){

						tabParent.stop(true, true).animate({
							height:'0px'

						},function() {
							tabContent.removeClass('open');
							currContent.addClass('open');
							tab.removeClass('open');
							ele.addClass('open');

							tabParent.stop(true, true).animate({
								height:currContent.height()+'px'
							});

						});

					}

			});

			$('.faq-row-handle > a:eq(0)').click();
	}


});
