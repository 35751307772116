$(document).on("turbolinks:load", function() {
	$( "#event_ticketed" ).click(function() {
	  $('#ticket-details').toggle();
	});
});

$(document).on("turbolinks:load", function() {
	$( "#event_partner_address" ).click(function() {
	  $('#address_details').toggle();
	});
});

$(document).on("turbolinks:load", function() {
	$( "#recurring_event_ticket" ).click(function() {
	  $('#ticket-details').toggle();
	});
});

$(document).on("turbolinks:load", function() {
  // Multiple images preview in browser
  var imagesPreview = function(input, placeToInsertImagePreview) {

      if (input.files) {
          var filesAmount = input.files.length;

          for (i = 0; i < filesAmount; i++) {
              var reader = new FileReader();

              reader.onload = function(event) {
                  $($.parseHTML('<img>')).attr('src', event.target.result).appendTo(placeToInsertImagePreview);
              }

              reader.readAsDataURL(input.files[i]);
          }
      }

  };

  $('#recurring_event_images').on('change', function() {
			$("div.recurring_event_gallery").html("");
			$("div.recurring_event_gallery_explanation").show();
			$("div.recurring_event_gallery").show();
      imagesPreview(this, 'div.recurring_event_gallery');
  });

	$('#recurring_event_banner').on('change', function() {
			$("div.recurring_event_banner").html("");
			$("div.recurring_event_banner_explanation").show();
			$("div.recurring_event_existing_banner").hide();
			$("div.recurring_event_banner").show();
      imagesPreview(this, 'div.recurring_event_banner');
  });

	$('#event_banner').on('change', function() {
			$("div.event_banner").html("");
			$("div.event_banner_explanation").show();
			$("div.event_existing_banner").hide();
			$("div.event_banner").show();
      imagesPreview(this, 'div.event_banner');
  });

	$('#event_images').on('change', function() {
			$("div.event_gallery").html("");
			$("div.event_gallery_explanation").show();
			$("div.event_gallery").show();
      imagesPreview(this, 'div.event_gallery');
  });

	$('#partner_profile_banner').on('change', function() {
			$("div.partner_profile_banner").html("");
			$("div.partner_profile_banner_explanation").show();
			$("div.partner_profile_existing_banner").hide();
			$("div.partner_profile_banner").show();
      imagesPreview(this, 'div.partner_profile_banner');
  });

	$('#partner_profile_avatar').on('change', function() {
			$("div.partner_profile_avatar").html("");
			$("div.partner_profile_avatar_explanation").show();
			$("div.partner_profile_existing_avatar").hide();
			$("div.partner_profile_avatar").show();
      imagesPreview(this, 'div.partner_profile_avatar');
  });

	$('#partner_profile_images').on('change', function() {
			$("div.partner_profile_gallery").html("");
			$("div.partner_profile_gallery_explanation").show();
			$("div.partner_profile_gallery").show();
      imagesPreview(this, 'div.partner_profile_gallery');
  });

	$('#farmers_market_banner').on('change', function() {
			$("div.farmers_market_banner").html("");
			$("div.farmers_market_banner_explanation").show();
			$("div.farmers_market_existing_banner").hide();
			$("div.farmers_market_banner").show();
      imagesPreview(this, 'div.farmers_market_banner');
  });

	$('#farmers_market_images').on('change', function() {
			$("div.farmers_market_gallery").html("");
			$("div.farmers_market_gallery_explanation").show();
			$("div.farmers_market_gallery").show();
      imagesPreview(this, 'div.farmers_market_gallery');
  });
	$('#place_banner').on('change', function() {
			$("div.place_banner").html("");
			$("div.place_banner_explanation").show();
			$("div.place_existing_banner").hide();
			$("div.place_banner").show();
      imagesPreview(this, 'div.place_banner');
  });

	$('#place_images').on('change', function() {
			$("div.place_gallery").html("");
			$("div.place_gallery_explanation").show();
			$("div.place_gallery").show();
      imagesPreview(this, 'div.place_gallery');
  });

	$('#walk_banner').on('change', function() {
			$("div.walk_banner").html("");
			$("div.walk_banner_explanation").show();
			$("div.walk_existing_banner").hide();
			$("div.walk_banner").show();
      imagesPreview(this, 'div.walk_banner');
  });

	$('#walk_images').on('change', function() {
			$("div.walk_gallery").html("");
			$("div.walk_gallery_explanation").show();
			$("div.walk_gallery").show();
      imagesPreview(this, 'div.walk_gallery');
  });

	$('#local_service_banner').on('change', function() {
			$("div.local_service_banner").html("");
			$("div.local_service_banner_explanation").show();
			$("div.local_service_existing_banner").hide();
			$("div.local_service_banner").show();
      imagesPreview(this, 'div.local_service_banner');
  });

	$('#local_service_logo').on('change', function() {
			$("div.local_service_avatar").html("");
			$("div.local_service_avatar_explanation").show();
			$("div.local_service_existing_avatar").hide();
			$("div.local_service_avatar").show();
      imagesPreview(this, 'div.local_service_avatar');
  });

	$('#local_service_images').on('change', function() {
			$("div.local_service_gallery").html("");
			$("div.local_service_gallery_explanation").show();
			$("div.local_service_gallery").show();
      imagesPreview(this, 'div.local_service_gallery');
  });
});

$(document).on("turbolinks:load", function() {
	$( "#event_internal_ticket" ).click(function() {
	  $('.non_woik_ticket_details').toggle();
	});
});


// $(document).on("turbolinks:load", function() {
// 	$( "#partner_nav_link" ).click(function() {
// 	  $('#partner_results_container').toggle();
// 		$('#event_results_container').toggle();
// 		$('#recurring_event_results_container').toggle();
// 	});
// });
//
// $(document).on("turbolinks:load", function() {
// 	$( "#events_nav_link" ).click(function() {
// 	  $('#partner_results_container').toggle();
// 		$('#event_results_container').toggle();
// 		$('#recurring_event_results_container').toggle();
// 	});
// });
//
// $(document).on("turbolinks:load", function() {
// 	$( "#recurring_events_nav_link" ).click(function() {
// 	  $('#partner_results_container').toggle();
// 		$('#event_results_container').toggle();
// 		$('#recurring_event_results_container').toggle();
// 	});
// });
